@import './reset';

#app-container {
	width: 100%;
	height: 100%;
	max-width: 100%;
	max-height: 100%;

	box-sizing: border-box;

	display: grid;
	grid-column-gap: 10px;
	grid-row-gap: 10px;

	grid-template-columns: repeat(12, 1fr);
	grid-template-rows: repeat(8, 1fr);
	grid-template-areas:
		"zoo zoo zoo rac rac rac rac lea lea lea lea lea"
		"zoo zoo zoo rac rac rac rac lea lea lea lea lea"
		"zoo zoo zoo trk trk trk trk lea lea lea lea lea"
		"ses ses ses trk trk trk trk lea lea lea lea lea"
		"ses ses ses trk trk trk trk lea lea lea lea lea"
		"car car pit pit pit pit pit pit pit whl whl whl"
		"car car pit pit pit pit pit pit pit whl whl whl"
		"car car pit pit pit pit pit pit pit whl whl whl"
		"pad pad pad pad pad pad pad pad pad pad pad pad";

	#leaderboard { grid-area: lea; }
	#race-data { grid-area: rac; }
	#zoomy { grid-area: zoo; }
	#car-setup { grid-area: car; }
	#track-map { grid-area: trk; }
	#wheels { grid-area: whl; }
	#pit-info { grid-area: pit; }
	#session-info { grid-area: ses; }
}


@font-face {
	font-family: 'Formula1';
	src: url('../../public/Formula1-Regular.ttf') format('truetype');
}