@import './colors';

html, body, #root {
	width: 100vw;
	height: 100vh;
	margin: 0;
	padding: 0;

	box-sizing: border-box;

	background-color: $bg;

	color: $white;

	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	font-family: 'Roboto Condensed', sans-serif;
	// font-family: 'Roboto Mono', monospace;
}

div {
	box-sizing: border-box;
}
